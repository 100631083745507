/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

$base-font-family: "Arvo", sans-serif;
$secondary-font-family: "Cabin Sketch", sans-serif;


$color-section: 				#8abfbb;
$color-text: 					#231f20;
$color-active: 					#ee5a00;
$color-active-dark: 			#bb4701;
$color-bg: 						#e4dfd8;

$breakpoint-tablet: 			768px;
$breakpoint-tablet-large: 		992px;
$breakpoint-laptop: 			1200px;