@import 'variables';
@import 'mixins';

/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body { font-family: $base-font-family; font-size:18px; background:$color-bg; }
img { max-width: 100%; }
ul { margin: 0; padding: 0; }

label { font-weight:400; padding: 0; margin: 0; }


/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header { 
	& {}

	@media (min-width: $breakpoint-laptop) {
		& { position:relative; }
	}

	.header-inner { 
		& { background: rgba(238,90,0,1); padding: 2px 0 10px 0; border-bottom:5px solid $color-active-dark; }

		@media (min-width: $breakpoint-laptop) {
			& { position: absolute; top:0; left:0; width:100%; height: 100%; background:transparent; border-bottom:0; }
		}
	}

	.header-aside { 
		& { padding: 23px 0 25px 0; text-align:center; background: url(images/wood.jpg) no-repeat 50% bottom; background-size: cover; }

		@media (min-width: $breakpoint-laptop) {
			& { text-align:right; }
		}
	}

	.btn-menu { 
		& { display: inline-block; width:31px; vertical-align: middle; }

		@media (min-width: $breakpoint-laptop) {
			& { display:none; }
		}
	}

	.btn-menu span { display:block; height: 5px; background:#313131; margin-bottom:5px; }
	.btn-menu span:last-child { margin-bottom:0; }

	.logo { 
		& { width:132px; display: inline-block; vertical-align: middle; margin-left:32px; }

		@media (min-width: $breakpoint-laptop) {
			& { position: relative; z-index: 22; width:222px; margin-left:0; }
		}
	}

	/* ------------------------------------------------------------ *\
		Callout Hours
	\* ------------------------------------------------------------ */

	.callout-hours { 
		& { width:250px; height: 137px; background: url(images/chalkbboard.png) no-repeat 0 0; font-family: $secondary-font-family; color: #fff; text-align:center; font-size:33px; line-height:.9; padding: 23px 0 0 0; display: inline-block; vertical-align: middle; }

		@media (min-width: $breakpoint-laptop) {
			& { position: relative; z-index: 1; }
		}
	}

	.callout-hours small { font-size:25px; display:block; color: inherit; }
	.callout-hours p { margin-bottom:5px; }
}


.callout {
	& { background:$color-section; margin: 30px -10px; padding: 20px 10px; border-bottom:5px solid #869e9c; position: relative; }
	
	.callout-inner { display: table; width:100%; }
	.callout-image { display: table-cell; vertical-align: middle; padding-right:10px; }
	.callout-body { display: table-cell; vertical-align: middle; }

	h2 { color: #3f2d20; font-size:22px; font-weight:700; margin: 0; }

	@media (min-width: $breakpoint-tablet) {
		& { margin: 30px -20px; }
	}

	@media (min-width: $breakpoint-tablet-large) {
		& { margin: 30px -10px 30px 0; }
		& h2 { font-size:35px; }
		&:before { content:''; background:$color-section; position: absolute; top:0; right:0; width:200%; bottom:-5px; border-bottom:5px solid #869e9c; }

		.callout-inner { position: relative; z-index: 1; }
	}
}


/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn { border-radius: 0; font-weight:700; background:#ee5a00; border:0; color: #fff; font-family: $base-font-family; box-shadow: 0px 5px 0px 0px $color-active-dark; 
	-webkit-transition: all .3s; 
	     -o-transition: all .3s; 
	        transition: all .3s; 
}
.btn.btn-small { font-size:15px; padding: 3px 8px 1px 8px; }

.btn:focus { color: #fff; box-shadow: none; background:$color-active-dark; }
.btn:hover { color: #fff; box-shadow: none; background:$color-active-dark; }


/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
	& { transform: translateX(-243px); background:#313131; color: #fff; margin: 0; position: fixed; top:0; height: 100%; width:243px; font-size:17px; padding: 30px 15px 0 20px; 

		-webkit-transition: all .3s;
		     -o-transition: all .3s;
		        transition: all .3s;
	}

	@media (min-width: $breakpoint-laptop) {
		& { transform: translateX(0); position: relative; top:0; width:100%; font-size:20px; padding: 0; z-index: 2; background: url(images/nav-bg.jpg) repeat-x 0 0; height: auto; z-index: 11; }
		&.navbar-nav { float:none; }
	}

	.nav-menu > li > a {
		& { position: relative; }
		&:after { content:''; position: absolute; top:0; right:0; background: url(images/ico-plus.png) no-repeat center center; width:14px; height: 14px; }
	}
	.nav-menu > li.dropdown.open > a:after { background: url(images/ico-minus.png) no-repeat center center; }

	a { background:transparent; color: inherit; color: #fff; font-weight:700; text-decoration: none; display:block; }
	li { list-style:none; list-style-position: outside; padding: 8px 0; }

	.dropdown { 
		& { font-size:15px; }

		@media (min-width: $breakpoint-laptop) {
			& { font-size:inherit; }
			&.open { background:$color-active-dark; }

			.dropdown-menu {
				& { font-size:17px; min-width: 267px; border-radius: 0; border:0; background-color:$color-active-dark; }
			}
		}
	}

	.dropdown .dropdown-menu { 
		& { padding-top:14px; }

		@media (min-width: $breakpoint-laptop) {
			& { margin: 0; }
		}
	}
	.dropdown .dropdown-menu>li>a { 
		& { color: #ee5a00; font-weight:400; }

		@media (min-width: $breakpoint-laptop) {
			& { color: #fff; font-weight:700; }
		}
	}
	.dropdown .dropdown-menu>li { 
		& { padding: 0; }

		@media (min-width: $breakpoint-laptop) {
			& { padding: 5px 0; }
		}
	}
	.dropdown .dropdown-menu>li>a { 
		& { white-space: pre-wrap; }

		@media (min-width: $breakpoint-laptop) {
			&:focus,
			&:hover { color: $color-active; }
		}
	}

	.open>a, 
	.open>a:focus, 
	.open>a:hover { background:transparent; color: inherit; }

	.nav-menu {
		& {}

		@media (min-width: $breakpoint-laptop) {
			& { display: table; width:100%; }
			& > li { display: table-cell; vertical-align: middle; padding: 0; }
			& > li > a { line-height:61px; text-align:center; }
			& > li > a:after { display:none; }
		}
	}
}


/* ------------------------------------------------------------ *\
	Content
\* ------------------------------------------------------------ */

.content {
	& {}
	@media (min-width: $breakpoint-tablet-large) {
		& { padding-right:365px; }
	}	
}


/* ------------------------------------------------------------ *\
	Sidebar
\* ------------------------------------------------------------ */

.sidebar {
	& { background:$color-bg; }

	@media (min-width: $breakpoint-tablet-large) {
		& { width:365px; float:right; padding-left:22px; position: relative; }
		&:before { content:''; position: absolute; top:0; left:0; width:200%; height: 4000px; background:#f3f1ed; }
	}
}

.widgets {
	& { position: relative; z-index: 1; }
	.widget { list-style:none; list-style-position: outside; }
	.widget-facebook { margin-bottom:35px; }

	.widget-instagram {
		& {}
		.widget-head { overflow: hidden; }
		.widget-head h6 { 
			& { color: $color-active; display: inline-block; vertical-align: middle; margin: 0; float:right; font-weight: 700; line-height:29px; }
			@media (min-width: $breakpoint-laptop) {
				& { font-size:15px; line-height:39px; }
			}
		}
		.widget-body {}
	}
}


	/* ------------------------------------------------------------ *\
		Nav Secondary
	\* ------------------------------------------------------------ */

	.nav-secondary {
		& { font-weight:700; font-size:23px; padding: 15px 0; }
		li { list-style:none; list-style-position: outside; padding-bottom:16px; }
		a { color: #fff; background:$color-active; display:block; position: relative; padding-left:73px; box-shadow: 0px 5px 0px 0px $color-active-dark; text-decoration: none; 
			-webkit-transition: all .3s; 
			     -o-transition: all .3s; 
			        transition: all .3s; 
		}
		a:hover { background:$color-active-dark; }
		.icon { position: absolute; top:0; left:0; width:73px; text-align:center; background: $color-active-dark; line-height:60px; }
		.text { display:block; padding: 14px; }
	}

	/* ------------------------------------------------------------ *\
		subscribe
	\* ------------------------------------------------------------ */

	.subscribe {
		& { position: relative; padding-bottom:45px; }
		label { font-size:16px; color: #262626; font-weight:400; margin-bottom:10px; }
		label strong { font-size:23px; font-weight:700; display:block; }
		label span { display:block; white-space: nowrap; }
		.subscribe-controls { position: relative; padding-bottom:5px; }
		.subscribe-field { font-size:18px; color: #959595; background:#fff; padding: 15px; display:block; width:100%; background:#fff; border:0; box-shadow: 0px 5px 0px 0px #cdcdcd; }
		.subscribe-btn { position: absolute; top:0; right:0; width:64px; background:$color-active; color: #fff; border:0; bottom:5px; font-weight:700; font-size:23px; box-shadow: 0px 5px 0px 0px $color-active-dark; }
	}






/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

.wrapper { 
	& { min-height: 100vh;
		-webkit-transition: all .3s;
		     -o-transition: all .3s; 
		        transition: all .3s; 
	}

	&.open  { 
		& { transform: translateX(243px); }

		.nav {
			transform: translateX(-243px);
		}

		.btn-menu {
			span:nth-child(1) { transform: rotate(45deg); }
			span:nth-child(2) { transform: rotate(-45deg); margin-top:-10px; }
			span:last-child { display:none; }
		}
	}
}


/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro {
	& { position: relative; min-height: 188px; }

	@media (min-width: $breakpoint-tablet) {
		& { display: table; width:100%; min-height: 420px; }
	}

	.intro-title { 
		& { font-size:47px; font-weight:700; text-shadow: 3px 3px 0px $color-active-dark; color: #fff; margin: 0; text-align:center; line-height:1.3; }
		@media (min-width: $breakpoint-tablet) {
			& { display: table-cell; vertical-align: middle; font-size:85px; }
		}
	}
	.intro-bg { position: absolute; top:0; left:0; width:100%; height: 100%; z-index: -1; background-repeat: no-repeat; background-position:50% 50%; background-size: cover; }
}


/* ------------------------------------------------------------ *\
	List Features
\* ------------------------------------------------------------ */

.list-features {
	& { text-align:center; font-size:0; }
	li { display: inline-block; list-style:none; list-style-position: outside; font-size:0; font-weight:700; width:218px; height: 218px; line-height:218px;  border-radius: 50%; background:#fff; text-align:center; box-shadow: 0px 4px 0px 0px #dcdcdc; }
	li a { color: $color-active; display: inline-block; vertical-align: middle; font-size:25px; line-height:1; }
	li + li { 
		& { margin-top:26px; }

		@media (min-width: $breakpoint-tablet) {
			& { margin-top:0; margin-left:20px; }
		}

		@media (min-width: $breakpoint-tablet-large) {
			& { margin-left:80px; }
		}
	}
	li span { display:block; font-weight:700; margin-top:10px; }
}

/* ------------------------------------------------------------ *\
	List Details
\* ------------------------------------------------------------ */

.list-details {
	& {}
	li { list-style:none; list-style-position: outside; }
	li + li { margin-top: 30px; }
	strong { color:$color-active-dark; }
}

.list-instagram {
	& { list-style:none; list-style-position: outside; font-size:0; margin: 0 -5px; }
	li { display: inline-block; vertical-align: top; width:33.3%; padding: 5px; }
}

.list-events {
	& {}
	@media (min-width: $breakpoint-tablet-large) {
		& { padding-left:73px; }
	}

	@media (min-width: $breakpoint-tablet-large) {
		& { padding-left:50px; }
	}

	li { position: relative; list-style:none; list-style-position: outside; margin-bottom:47px; line-height:1.2; }
	li:before { content:''; background: url(images/ico-calendar.png) no-repeat 0 0; width:39px; height: 40px; display: inline-block; position: absolute; top:0; left:-50px; }
}


/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.sections {
	& { margin: 0 -10px; }

	@media (min-width: $breakpoint-tablet) {
		& { margin: 0 -20px; }
	}

	@media (min-width: $breakpoint-tablet-large) {
		& { margin: 0; }
	}
}

.section {
	& { padding:30px 10px 17px 73px; font-size:20px; position: relative; }

	@media (min-width: $breakpoint-tablet-large) {
		& { padding-top:45px; }
	}

	.section-inner {
		& { position: relative; }

		@media (min-width: $breakpoint-tablet-large) {
			& { z-index: 1; display: table; width:100%; }
		}
	}

	&.section:nth-child(even) {
		& { background:#d7d2cb; }

		@media (min-width: $breakpoint-tablet-large) {
			&:after { content:''; background:#d7d2cb; position: absolute; top:0; right:0; width:200%; height: 100%; }
		}		
	}

	h4 { font-size:20px; color: #231f20; font-weight:700; margin: 0; }

	.section-head { margin-bottom:41px; position: relative; }
	.section-title { font-weight:700; font-size:35px; margin: 0; line-height:1.2; }
	.section-image { 
		& { display: inline-block; margin-bottom:10px; }

		@media (min-width: $breakpoint-laptop) {
			& { display: table-cell; vertical-align: middle;  }
		}
	}

	.section-body {
		& { position: relative; }

		@media (min-width: $breakpoint-laptop) {
			& { display: table-cell; vertical-align: middle; width:60%; padding-right:30px; }
		}
	}

	.ico-corn { 
		& { content:''; position: absolute; top:-15px; left:-70px; width:51px; height: 79px; background: url(images/ico-corn.png) no-repeat 0 0; z-index: 2; }

		@media (min-width: $breakpoint-tablet) {
			& { left:-60px; }
		}

		@media (min-width: $breakpoint-laptop) {
			& { top:50%; left:-70px; transform: translateY(-50%); }
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Article
\* ------------------------------------------------------------ */

.section-article {
	& { padding: 60px 10px 60px 0; }
}



/* ------------------------------------------------------------ *\
	Section Secondary
\* ------------------------------------------------------------ */

.section-secondary {
	& { background: $color-section; padding: 20px 0; border-bottom:5px solid #869e9c; }

	@media (min-width: $breakpoint-tablet-large) {
		& { padding: 30px 0; }
	}

	.section-title { font-size:40px; font-weight:700; text-shadow: 3px 3px 0px $color-active-dark; color: #fff; margin:0 0 30px 0; text-align:center; padding: 0 20px; }
}

.partners {
	& { padding: 0 10px; }
}
.partner {
	& { margin: 0 -10px; padding-bottom:10px; }

	@media (min-width: $breakpoint-tablet-large) {
		& { margin: 0 -5px; padding-bottom:20px; }
	}

	img { display:block; width:100%; }
}


/* ------------------------------------------------------------ *\
	Slider Intro
\* ------------------------------------------------------------ */

.slider-intro {
	& { position: relative; padding-bottom:40px; z-index: 9; }

	@media (min-width: $breakpoint-tablet-large) {
		& { padding: 60px 0; }
	}

	.slide { 
		& { text-align:center; padding-bottom:41px;  }

		@media (min-width: $breakpoint-tablet-large) {
			& { padding-bottom:85px; }
		}
	}
	.slide h1 { 
		& { font-size:41px; font-weight:700; text-shadow: 3px 3px 0px $color-active-dark; color: #fff; margin-bottom:30px; }

		@media (min-width: $breakpoint-tablet-large) {
			& { font-size:85px; }
		}
	}
	.slide-content { padding: 0 40px; }

	.slick-dots { font-size:0; text-align:center; }
	.slick-dots li { display: inline-block; vertical-align: middle; margin: 0 5px; }
	.slick-dots li.slick-active button { background:#8abfbb; }
	.slick-dots button { width:20px; height: 20px; border-radius: 50%; border:0; background:#fff; font-size: 0; line-height: 0; text-indent: -4000px; }

	.slick-arrow { position: absolute; top:50%; transform: translateY(-50%); }
	.slider-prev { left:0; }
	.slider-next { right:0; }
}

/* ------------------------------------------------------------ *\
	Main 
\* ------------------------------------------------------------ */

.main {
	& { overflow: hidden; }
	.main-head { padding: 35px 0; background: url(images/wood-main.jpg) no-repeat 50% 0; background-size: cover; text-align:center; }
	.main-title { 
		& { color: #fff; font-size:25px; font-weight:700; margin: 0; line-height:1.2; }

		@media (min-width: $breakpoint-tablet-large) {
			& { font-size:45px; }
		}
	}
	.main-title br { display:none; }
}





























/* ------------------------------------------------------------ *\
	Sprite
\* ------------------------------------------------------------ */


.ico-calendar { background-image: url(images/sprite.png); background-position: -150px -248px; width: 39px; height: 40px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-corn { background-image: url(images/sprite.png); background-position: -369px -71px; width: 51px; height: 79px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-donut { background-image: url(images/sprite.png); background-position: -64px -248px; width: 82px; height: 60px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-instagram-logo-mobile { background-image: url(images/sprite.png); background-position: -118px -162px; width: 113px; height: 29px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-instagram-logo { background-image: url(images/sprite.png); background-position: 0 -205px; width: 153px; height: 39px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-left-small { background-image: url(images/sprite.png); background-position: -369px -154px; width: 36px; height: 53px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-map { background-image: url(images/sprite.png); background-position: -217px -87px; width: 37px; height: 43px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-maze { background-image: url(images/sprite.png); background-position: -267px 0; width: 98px; height: 66px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-next { background-image: url(images/sprite.png); background-position: 0 -248px; width: 60px; height: 94px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-photo { background-image: url(images/sprite.png); background-position: -369px -304px; width: 44px; height: 34px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-prev { background-image: url(images/sprite.png); background-position: -267px -70px; width: 60px; height: 94px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-pumpkin-small { background-image: url(images/sprite.png); background-position: -369px 0; width: 67px; height: 67px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-pumpkin-text { background-image: url(images/sprite.png); background-position: 0 0; width: 263px; height: 83px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-pumpkin { background-image: url(images/sprite.png); background-position: 0 -87px; width: 114px; height: 114px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-right-small { background-image: url(images/sprite.png); background-position: -369px -211px; width: 36px; height: 53px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-scissors { background-image: url(images/sprite.png); background-position: -369px -268px; width: 53px; height: 32px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-tickets { background-image: url(images/sprite.png); background-position: -267px -168px; width: 44px; height: 28px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-tractor { background-image: url(images/sprite.png); background-position: -118px -87px; width: 95px; height: 71px; display: inline-block; vertical-align: middle; font-size: 0; }


.container { padding-right: 10px; padding-left: 10px; }

/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	& { background-color: $color-active; color: #fff; text-align:center; padding: 45px 0 155px 0; }

	h2 { margin: 0 0 25px 0; font-size:35px; font-weight:700; }
	h4 { font-size:20px; font-weight:700; margin-bottom:30px; margin-top:0; }
	.copyright { font-size:10px; margin-bottom:25px; }

	.cards { text-align:center; }
}



/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */

.shell {
	& {}

	@media (min-width: $breakpoint-laptop) {
		& { width:1170px; margin: 0 auto; }
	}
}